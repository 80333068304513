import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Home } from "./pages/home/Home";
import { GameDetails } from "./pages/game-details/GameDetails";
import { Maintenence } from "./pages/maintenence/Maintenence";

import "./App.scss";

function App() {
  if (process.env.SITE_UNDER_DEVELOPMENT === "true") {
    return <Maintenence />;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game/:id" element={<GameDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
