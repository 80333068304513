import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import config from "../../config.json";

export interface ILocaleState {
  language: string;
  supportedLanguages: string[];
}

export const localeSlice = createSlice({
  name: "locale",
  initialState: {
    language: config.defaultLanguage,
    supportedLanguages: config.supportedLanguages,
  } as ILocaleState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = localeSlice.actions;
