import { Layout } from "antd";
import { Footer, Content } from "antd/es/layout/layout";

import "./Maintenence.scss";

export function Maintenence(): JSX.Element {
  return (
    <Layout className="layout">
      <Content>
        <div className="logo">
          <img src="logo192_transparent.png" alt="Animated Logo" />
        </div>
      </Content>
      <Footer>
        <div className="copyright">
          <p className="name">2023 AIR FORGE GAMES L.L.C.</p>
          <p className="address">
            2501 Chatham Rd Suite 4438
            <br />
            Springfield, IL 62704
          </p>
          <p className="contact">
            +1 (224) 215-0789
            <br />
            contact@airforgegames.com
          </p>
        </div>
      </Footer>
    </Layout>
  );
}
