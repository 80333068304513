import { createSlice } from "@reduxjs/toolkit";

import { IGame } from "../../models/game";

import config from "../../config.json";

export interface IGamesState {
  games: IGame[];
}

export const gamesSlice = createSlice({
  name: "games",
  initialState: {
    games: config.games as unknown as IGame[],
  } as IGamesState,
  reducers: {},
});
