import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import config from "../config.json";

import homeEN from "./en/home.json";
import homeUK from "./uk/home.json";
import homePL from "./pl/home.json";

import { store } from "../state/store";
import { setLanguage } from "../state/slices/locale-slice";

i18n
  .use(initReactI18next)
  .use(new LanguageDetector(null, { lookupLocalStorage: "language" }))
  .init(
    {
      resources: {
        en: {
          home: homeEN,
        },
        uk: {
          home: homeUK,
        },
        pl: {
          home: homePL,
        },
      },
      fallbackLng: config.defaultLanguage,
      supportedLngs: config.supportedLanguages,
      interpolation: {
        escapeValue: false,
      },
      nonExplicitSupportedLngs: true,
    },
    (error) => {
      store.dispatch(
        setLanguage(error ? config.defaultLanguage : i18n.language)
      );
    }
  );

export default i18n;
