import HoverVideoPlayer from "react-hover-video-player";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IGame } from "../../models/game";

import "./GamePreview.scss";

interface GamePreviewProps {
  game: IGame;
}

export function GamePreview(props: GamePreviewProps): JSX.Element {
  const { t } = useTranslation("home");

  return (
    <Link to={`${props.game.link}`} target="_blank">
      <HoverVideoPlayer
        pausedOverlay={
          <img
            src={t(props.game.coverImage).toString()}
            alt=""
            className="cover-image"
          />
        }
        restartOnPaused={true}
        videoSrc={t(props.game.introVideo).toString()}
        className="player"
        videoClassName="video"
      />
    </Link>
  );
}
