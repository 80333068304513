import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../../state/hooks";

import "./GameDetails.scss";

export function GameDetails(): JSX.Element {
  const { t } = useTranslation("home");

  const location = useLocation();
  const gameId = location.pathname.split("/").pop();

  const game = useAppSelector((state) => state.games).games.find(
    (game) => game.id === gameId
  );

  return (
    <>
      {game && (
        <div>
          <h1>{t(game.name).toString()}</h1>
          <ReactPlayer
            url={t(game.gameplayVideo).toString()}
            loop={true}
            playing={true}
            width="100vw"
            height="100vh"
            muted={true}
          />
        </div>
      )}
    </>
  );
}
