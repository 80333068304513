import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import { ITeam } from "../../models/team";

import "./TeamContact.scss";

interface TeamContactProps {
  team: ITeam;
}

export function TeamContact(props: TeamContactProps): JSX.Element {
  const { t } = useTranslation("home");
  return (
    <Col className="team-contact">
      <Row className="name" justify="center">{t(props.team.name)}</Row>
      <Row className="address" style={{whiteSpace: 'pre-line'}} justify="center">{t(props.team.address)}</Row>
      <Row className="phone" justify="center">{t(props.team.phone)}</Row>
      <Row className="email" justify="center">{t(props.team.email)}</Row>
    </Col>
  );
}
