export function memberFullNameToProfileImage(fullName: string): string {
  switch (fullName) {
    case "ihor-karalash":
      return "/ihor.jpg";
    case "andrii-karalash":
      return "/andrii.jpg";
    case "mariia-karalash":
      return "/mariia.jpg";
    case "stanislav-hundiak":
      return "stanislav.jpg"
    case "mykhailo-karalash":
      return "mykhailo.jpg";
    case "roman-dubchak":
      return "roman.jpg";
    default:
      return "unknown";
  }
}
