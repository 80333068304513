import { Col, Row } from "antd";
import { useState } from "react";

import { GamePreview } from "../../components/game-preview/GamePreview";
import { LanguagePicker } from "../../components/language-picker/LanguagePicker";
import { ParalaxLayout } from "../../components/paralax-layout/ParalaxLayout";
import { TeamContact } from "../../components/team-contact/TeamContact";

import config from "../../config.json";
import { IGame } from "../../models/game";
import { useAppSelector } from "../../state/hooks";

import "./Home.scss";
import { TeamPreview } from "../../components/team-preview/TeamPreview";
import { TeamDescription } from "../../components/team-description/TeamDescription";

export function Home(): JSX.Element {
  const { games } = useAppSelector((state) => state.games);
  const { team } = useAppSelector((state) => state.team);

  const [showLanguagePicker, setShowLanguagePicker] = useState(true);

  return (
    <>
      <ParalaxLayout
        headerImages={config.headerImages}
        content={
          <Col>
            <Row className="games">
              {games.map((game: IGame, index: number) => (
                <Col key={index} sm={12} xs={24}>
                  <GamePreview game={game} />
                </Col>
              ))}
            </Row>
            <TeamDescription team={team} />
            <TeamPreview team={team} />
          </Col>
        }
        footer={<TeamContact team={team} />}
        onScroll={(scrollTop: number) => setShowLanguagePicker(scrollTop === 0)}
      />
      <LanguagePicker visible={showLanguagePicker} />
    </>
  );
}
