export function languageCodeToFlagImage(languageCode: string): string {
    switch (languageCode) {
      case "en":
        return "/us.svg";
      case "uk":
        return "/ua.svg";
      case "pl":
        return "/pl.svg";
      default:
        return "unknown";
    }
  }