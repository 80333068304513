import { configureStore } from "@reduxjs/toolkit";

import { gamesSlice } from "./slices/games-slice";
import { localeSlice } from "./slices/locale-slice";
import { teamSlice } from "./slices/team-slice";

export const store = configureStore({
  reducer: {
    locale: localeSlice.reducer,
    games: gamesSlice.reducer,
    team: teamSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
