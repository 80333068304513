import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import { ITeam } from "../../models/team";

import "./TeamDescription.scss";

interface ITeamDescriptionProps {
  team: ITeam;
}

export function TeamDescription(props: ITeamDescriptionProps): JSX.Element {
  const { t } = useTranslation("home");
  const credo: string = t(props.team.credo);

  return (
    <>
      <Row className="team-description" justify="start" align="top">
        <Col style={{ flex: "0 0 250px" }}>
          <img src="logo192_white_transparent.png" alt="" />
        </Col>
        <Col className="credo">
          <h1>
            <span className="first-word">{credo.split(" ").at(0)} </span>
            <span className="second-word">{credo.split(" ").at(1)}</span>
          </h1>
          <span className="description">{t(props.team.description)}</span>
        </Col>
      </Row>
    </>
  );
}
