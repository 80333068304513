import { createSlice } from "@reduxjs/toolkit";

import { ITeam } from "../../models/team";

import config from "../../config.json";

export interface ITeamState {
  team: ITeam;
}

export const teamSlice = createSlice({
  name: "team",
  initialState: {
    team: config.team as unknown as ITeam,
  } as ITeamState,
  reducers: {},
});
