import { useState } from "react";
import classNames from "classnames";

import { store } from "../../state/store";
import { ILocaleState, setLanguage } from "../../state/slices/locale-slice";
import { languageCodeToFlagImage } from "./LanguagePicker.utils";
import { useAppSelector } from "../../state/hooks";
import i18n from "../../locale/i18n";

import "./LanguagePicker.scss";

interface ILanguagePickerProps {
  visible?: boolean;
}

export function LanguagePicker({
  visible = true,
}: ILanguagePickerProps): JSX.Element {
  const locale: ILocaleState = useAppSelector((state) => state.locale);

  const [activeLanguage, setActiveLanguage] = useState(locale.language);

  function changeLanguage(languageCode: string): void {
    i18n.changeLanguage(languageCode);
    store.dispatch(setLanguage(languageCode));
  }

  return (
    <div
      className={classNames("languages", {
        visible: visible,
        hidden: !visible,
      })}
    >
      {locale.supportedLanguages?.map((language: string, index: number) => (
        <div
          key={index}
          className={classNames("language", {
            "active-language": activeLanguage === language,
          })}
          onMouseEnter={() => setActiveLanguage(language)}
          onMouseLeave={() => setActiveLanguage(locale.language)}
          onMouseDown={() => changeLanguage(activeLanguage)}
        >
          <img src={languageCodeToFlagImage(language)} alt="" />
        </div>
      ))}
    </div>
  );
}
