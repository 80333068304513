import "./ParalaxLayout.scss";

interface IHeaderProps {
  headerImages?: string[];
  content?: JSX.Element;
  footer?: JSX.Element;
  onScroll?: (value: number) => void;
}

export function ParalaxLayout(props: IHeaderProps): JSX.Element {
  return (
    <div
      className="parallax"
      onScroll={(e) => props.onScroll?.(e.currentTarget.scrollTop)}
    >
      {props.headerImages?.map((imagePath: string, index: number) => (
        <div
          key={index}
          className={`parallax__layer parallax__layer__${index}`}
        >
          <img src={imagePath} alt="" />
        </div>
      ))}
      <div className="parallax__cover">
        <div className="parallax__content">
          {props.content}
          {props.footer}
        </div>
      </div>
    </div>
  );
}
