import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import "./locale/i18n";
import "./fonts";
import "./index.scss";

import { store } from "./state/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
