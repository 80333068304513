import { Avatar, Card, Carousel, Col, Row } from "antd";
import { useTranslation } from "react-i18next";

import { IMember } from "../../models/member";
import { ITeam } from "../../models/team";
import { memberFullNameToProfileImage } from "./TeamPreview.utils";

import "./TeamPreview.scss";
import Meta from "antd/es/card/Meta";

interface TeamPreviewProps {
  team: ITeam;
}

export function TeamPreview(props: TeamPreviewProps): JSX.Element {
  const { t } = useTranslation("home");

  return (
    <>
      <Carousel
        className="team"
        slidesToShow={4}
        pauseOnHover
        autoplay
        arrows
        dots={false}
      >
        {props.team.members.map((member: IMember, index: number) => (
          <Card
            className="member"
            style={{ margin: "25px 12.5px 25px 12.5px" }}
            hoverable
            cover={
              <img src={memberFullNameToProfileImage(member.fullName)} alt="" />
            }
          >
            <Meta
              title={
                <>
                  <span className="first-name">
                    {t(member.fullName).split(" ").at(0)}{" "}
                  </span>
                  <span className="last-name">
                    {t(member.fullName).split(" ").at(1)}
                  </span>
                </>
              }
              description={<span className="role">{member.role.map((role) => t(role)).join(", ")}</span>}
            />
          </Card>
        ))}
      </Carousel>
    </>
  );
  /*return (
    <Col className="team">
      <Row justify="center" gutter={[25,25]}>
        {props.team.members.map((member: IMember, index: number) => (
          <Col className="member" key={index}>
            <Card
              hoverable
              cover={
                <img
                  src={memberFullNameToProfileImage(member.fullName)}
                  alt=""
                />
              }
            >
              <Meta
                title={t(member.fullName)}
                description={member.role.map(role => t(role)).join(', ')}
              />
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );*/
}
